











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab5Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'It is very flammable and can cause serious damage to the lab',
          value: 'veryFlammable',
        },
        {
          text: 'It is very toxic and exposure to it can result in serious damage to health',
          value: 'veryToxic',
        },
        {
          text: 'It is very likely to cause side reactions hence ethanol will be used instead',
          value: 'sideReactions',
        },
        {
          text: 'It is very expensive and too much is used in the lab.',
          value: 'expensive',
        },
      ],
      optionsFr: [
        {
          text: 'Il est très inflammable et peut endommager le laboratoire',
          value: 'veryFlammable',
        },
        {
          text: 'Il est très toxique et l’exposition à l’acétone peut entraîner des conséquences négatives à la santé',
          value: 'veryToxic',
        },
        {
          text: "Il est très probable que l’usage de l’acétone produira d’autres réactions donc de l'éthanol sera utilisé à sa place",
          value: 'sideReactions',
        },
        {
          text: 'Il est très dispendieux et trop d’acétone est utilisé au laboratoire',
          value: 'expensive',
        },
      ],
    };
  },
};
